import { persistUTMQueryParamsToUrlObject } from "@common/utils/router";
import { Loader } from "@mantine/core";
import { useSessionStore } from "@stores/session";
import { useRouter } from "next/router";
import { PropsWithChildren, ReactElement, useEffect, useState } from "react";

export default function AccountContainer({
  children,
}: PropsWithChildren): ReactElement {
  const router = useRouter();

  // session
  const { loading, account, getAccountDetails } = useSessionStore((state) => ({
    loading: state.loading,
    account: state.account,
    getAccountDetails: state.getAccountDetails,
  }));

  // first account loading
  const [firstLoading, setFirstLoading] = useState(true);
  useEffect(() => {
    async function getAccountDetailsAsync() {
      await getAccountDetails();
      setFirstLoading(false);
    }

    getAccountDetailsAsync();
  }, [getAccountDetails]);

  // Is it a restricted page?
  const visible = !!(
    process.env.VISIBLE_PATHS.indexOf(router.pathname) > -1 ||
    process.env.VISIBLE_PATHS.indexOf(router.asPath) > -1
  );
  useEffect(() => {
    if (!visible && !loading && !account && router) {
      router.replace(
        persistUTMQueryParamsToUrlObject(router, "/", {
          redirectTo: router.asPath,
        }),
      );
    }
  }, [visible, loading, account, router]);

  if (!visible && !loading && !account) {
    return <></>;
  }

  if (loading && firstLoading) {
    return <Loader m="xl" />;
  }

  return <>{children}</>;
}
