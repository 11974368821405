import { useRouter } from "next/router";
import { ReactElement, useCallback } from "react";
import { useHeaderNavLinkStyles, useLogoLinkStyles } from "./HeaderNav.styled";
import {
  persistUTMQueryParamsToString,
  persistUTMQueryParamsToUrlObject,
} from "@common/utils/router";
import {
  Anchor,
  Badge,
  Box,
  Burger,
  Flex,
  Header,
  MediaQuery,
  NavLink,
  Text,
  useMantineTheme,
} from "@mantine/core";
import {
  HEADER_NAV_HEIGHT,
  HEADER_NAV_HEIGHT_WITH_MESSAGE,
  HEADER_NAV_MESSAGE_HEIGHT,
} from "@components/navigation/HeaderNav/constants";
import { IHeaderNavProps } from "./types";
import { getEnv, isProduction } from "@common/utils/config";
import Link from "next/link";
import { SECTION_BILLING_ADDRESS } from "@pages/account/manage/billing";
import { customerStore } from "@stores/customer";
import { paymentMethodsStore } from "@stores/payment-methods";
import IconArrowRight from "@common/components/icons/IconArrowRight";
import LogoMetabase from "@common/components/icons/LogoMetabase";
import LogoMetabaseStore from "@common/components/icons/LogoMetabaseStore";
import Logo from "@common/components/icons/Logo";
import { purchasesStore } from "@stores/purchases";
import { useSessionStore } from "@stores/session";

export default function HeaderNav({
  opened,
  onBurgerClick,
}: IHeaderNavProps): ReactElement {
  const theme = useMantineTheme();
  const { classes: logoLinkClasses } = useLogoLinkStyles();
  const { classes: headerNavLinkStyles } = useHeaderNavLinkStyles();

  const router = useRouter();
  const { pathname, query } = router;
  const { step } = query;

  // customer
  const { isLoading: customerDetailsLoading, data: customerDetails } =
    customerStore.useGetDetails();

  // purchases
  const { data: purchases } = purchasesStore.useGetPurchases();

  // payment
  const { isLoading: loadingDefaultPaymentMethod, data: defaultPaymentMethod } =
    paymentMethodsStore.useDefaultPaymentMethod();

  // session
  const { account, logout } = useSessionStore((state) => ({
    account: state.account,
    logout: state.logout,
  }));

  // logout
  const logoutCallback = useCallback(async () => {
    const { success } = await logout();
    if (success) {
      router.push(persistUTMQueryParamsToUrlObject(router, "/"));
    }
  }, [logout, router]);

  // are we in one of the checkout pages? `/checkout`
  const isSuccessStep = step === "success";
  const isCheckoutPathname = !!(pathname.indexOf("/checkout") > -1);
  const showAccountLink = !isCheckoutPathname || purchases?.length;

  // billing address message
  const hasBillingAddressMessage =
    !isCheckoutPathname &&
    !customerDetailsLoading &&
    !loadingDefaultPaymentMethod &&
    defaultPaymentMethod &&
    !customerDetails?.billing_address;

  return (
    <Header
      id="main-header"
      height={
        hasBillingAddressMessage
          ? HEADER_NAV_HEIGHT_WITH_MESSAGE
          : HEADER_NAV_HEIGHT
      }
    >
      {hasBillingAddressMessage && (
        <Box data-testid="HeaderNav-message" bg="yellow.1" ta="center" w="100%">
          <Flex
            align="center"
            direction="row"
            justify="center"
            h={HEADER_NAV_MESSAGE_HEIGHT}
            p="xs"
            w="100%"
          >
            <Text fz={{ base: "xs", xs: "sm" }} mr="xs">
              Looks like we don&apos;t have a billing address.
            </Text>
            <Anchor
              data-testid="HeaderNav-message_billing-address"
              component={Link}
              href={persistUTMQueryParamsToString(
                router,
                `/account/manage/billing#section=${SECTION_BILLING_ADDRESS}`,
              )}
            >
              <Flex align="center" direction="row" justify="center">
                <Text
                  span
                  fw={700}
                  fz={{ base: "xs", xs: "sm" }}
                  mr={theme.spacing.xs / 2}
                >
                  Add a billing address
                </Text>
                <IconArrowRight />
              </Flex>
            </Anchor>
          </Flex>
        </Box>
      )}

      <Flex
        align="center"
        direction="row"
        justify="space-between"
        wrap="nowrap"
      >
        <Flex align="center" direction="row" wrap="nowrap" ml="lg">
          <Link
            href={persistUTMQueryParamsToUrlObject(
              router,
              account ? "/account" : "/",
            )}
            className={logoLinkClasses.root}
          >
            <Flex
              align="center"
              direction="row"
              justify="center"
              h="100%"
              px="sm"
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Box ml={-9} mt={-1}>
                  <Logo height={41} />
                </Box>
              </MediaQuery>
              <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                <Box>{account ? <LogoMetabaseStore /> : <LogoMetabase />}</Box>
              </MediaQuery>
            </Flex>
          </Link>

          {!isProduction() && (
            <Badge size="xs" bg="brand.3">
              {getEnv(true)}
            </Badge>
          )}
        </Flex>

        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <Flex
            align="baseline"
            direction="row"
            justify="space-between"
            pr="lg"
            wrap="nowrap"
          >
            {account && isSuccessStep && (
              <>
                <NavLink
                  component="a"
                  className={headerNavLinkStyles.root}
                  label="Docs"
                  icon={undefined}
                  href={persistUTMQueryParamsToString(
                    router,
                    "https://metabase.com/docs",
                  )}
                  target="_blank"
                  rel="noreferrer"
                />
                <NavLink
                  component={Link}
                  className={headerNavLinkStyles.root}
                  label="Support"
                  icon={undefined}
                  href={persistUTMQueryParamsToString(
                    router,
                    "https://www.metabase.com/help-premium",
                  )}
                  target="_blank"
                  rel="noreferrer"
                />
                <NavLink
                  component={Link}
                  className={headerNavLinkStyles.root}
                  label="Account"
                  icon={undefined}
                  href={persistUTMQueryParamsToString(router, "/account")}
                />
              </>
            )}

            {account && showAccountLink && (
              <>
                <NavLink
                  component={Link}
                  className={`${headerNavLinkStyles.root} ${headerNavLinkStyles.main}`}
                  label={`Logged in as ${account.first_name}`}
                  href="/account/manage"
                />
                <NavLink
                  data-testid="nav-link-logout"
                  className={`${headerNavLinkStyles.root} ${headerNavLinkStyles.main}`}
                  label="Logout"
                  icon={undefined}
                  onClick={logoutCallback}
                />
              </>
            )}
          </Flex>
        </MediaQuery>
        {!account && (
          <div>
            <NavLink
              component={Link}
              className={`${headerNavLinkStyles.root} ${headerNavLinkStyles.main}`}
              pr="lg"
              label="Manage Metabase account"
              icon={undefined}
              href={persistUTMQueryParamsToString(router, "/")}
              data-testid="nav-link-login"
            />
          </div>
        )}
        {account && !isCheckoutPathname && (
          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Burger
              opened={opened}
              onClick={onBurgerClick}
              size="sm"
              color={theme.colors.gray[7]}
              mr="xl"
            />
          </MediaQuery>
        )}
      </Flex>
    </Header>
  );
}
