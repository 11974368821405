import { createStyles } from "@mantine/core";

export const usePurchaseRowStyles = createStyles((theme) => ({
  cell: {
    width: "45%",

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      minWidth: "36%",
      width: "auto",
    },
  },
}));
