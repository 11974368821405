import { HEALTH_HEALTHY, STATUS_RUNNING } from "@stores/instances/constants";
import {
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_CANCELLATION_PENDING,
  SUBSCRIPTION_STATUS_CANCELLED,
  SUBSCRIPTION_STATUS_TRIAL,
} from "@stores/subscriptions/constants";
import {
  CreateInstanceDataType,
  IInstanceSetupData,
  IPurchase,
  IPurchaseTrialUp,
} from "./types";
import {
  HOSTING_FEATURE_CLICKHOUSE_DWH,
  HOSTING_FEATURE_CUSTOM_DOMAIN,
  HOSTING_FEATURE_TRIAL_UP,
} from "./constants";
import { getPlanAlias } from "@common/config/plan/utils";
import { captureException } from "@sentry/nextjs";

export function getCreateInstanceData({
  isDWH = false,
  planObj,
  isSelfHosted = false,
  isAnnualBilling = false,
  dnsAlias: dns_alias,
  region,
  migrationId,
}: IInstanceSetupData): CreateInstanceDataType | null {
  if (planObj) {
    return {
      plan_alias: getPlanAlias({
        planSlug: planObj.slug,
        isSelfHosted,
        isDWH,
        isAnnualBilling,
      }),
      dns_alias,
      region,
      migration_id: migrationId,
    };
  } else {
    captureException(new Error("getCreateInstanceData: planObj is undefined"));
  }

  return null;
}

export function isCanceledOrDeleted({
  subscription,
  hosted_instance,
}: Pick<IPurchase, "subscription" | "hosted_instance">): boolean {
  // Not using subscription.is_canceled because that includes cancellation pending
  return (
    subscription.status === SUBSCRIPTION_STATUS_CANCELLED ||
    hosted_instance?.is_deleted === true
  );
}

export function isBlocked({
  subscription,
  hosted_instance,
}: Pick<IPurchase, "subscription" | "hosted_instance">): boolean {
  return (
    isCanceledOrDeleted({
      subscription,
      hosted_instance,
    }) || hosted_instance?.health !== HEALTH_HEALTHY
  );
}

// Sort by:
//  - status: trial/active, followed by cancellation-pending, followed by cancelled
//  - within each status, sort by purchase date with recent purchase at the top
export function comparePurchase(
  purchase1: Pick<IPurchase, "subscription" | "hosted_instance">,
  purchase2: Pick<IPurchase, "subscription" | "hosted_instance">,
): number {
  return (
    statusScore(purchase2) - statusScore(purchase1) ||
    purchase2.subscription.created_at.localeCompare(
      purchase1.subscription.created_at,
    )
  );
}

function statusScore({
  subscription,
  hosted_instance,
}: Pick<IPurchase, "subscription" | "hosted_instance">): number {
  if (
    isCanceledOrDeleted({
      subscription,
      hosted_instance,
    })
  ) {
    return 0;
  } else if (subscription.status === SUBSCRIPTION_STATUS_CANCELLATION_PENDING) {
    return 1;
  } else {
    return 2;
  }
}

// changing plan
// - purchase is not canceled
// - instance is `running` OR self-hosted
// - subscription is `active` OR in `trial`
export function canChangePlan({
  hosted_instance,
  subscription,
}: Pick<IPurchase, "subscription" | "hosted_instance">): boolean {
  return (
    !isCanceledOrDeleted({ hosted_instance, subscription }) &&
    !!(
      (!hosted_instance || hosted_instance.status === STATUS_RUNNING) &&
      (subscription.status === SUBSCRIPTION_STATUS_ACTIVE ||
        subscription.status === SUBSCRIPTION_STATUS_TRIAL)
    )
  );
}

function hasHostingFeature(
  { hosting_features }: Pick<IPurchase, "hosting_features">,
  hostingFeature: string,
): boolean {
  if (hosting_features) {
    return !!(Object.keys(hosting_features).indexOf(hostingFeature) > -1);
  }

  return false;
}

// DWH
export function hasDWH(purchase: Pick<IPurchase, "hosting_features">): boolean {
  return hasHostingFeature(purchase, HOSTING_FEATURE_CLICKHOUSE_DWH);
}

// We allow custom domain only if present in the hosting features list
export function canHaveACustomDomain(
  purchase: Pick<IPurchase, "hosting_features">,
): boolean {
  return hasHostingFeature(purchase, HOSTING_FEATURE_CUSTOM_DOMAIN);
}

// Can create new instance?
export function canAccountCreateNewInstance(
  purchases: Pick<IPurchase, "subscription">[] | null,
): boolean {
  const purchasesInTrial = purchases?.filter(
    (purchase) => purchase.subscription.in_trial,
  );
  return !purchasesInTrial || (purchasesInTrial && purchasesInTrial.length < 2);
}

// Is purchase eligible for pro trial?
export function canTryProPlan(
  purchase: Pick<IPurchase, "hosting_features" | "subscription">,
): boolean {
  return (
    hasHostingFeature(purchase, HOSTING_FEATURE_TRIAL_UP) &&
    purchase.subscription.status === SUBSCRIPTION_STATUS_ACTIVE
  );
}

export function getProTrial({
  trial_ups,
}: Pick<IPurchase, "trial_ups">): IPurchaseTrialUp | undefined {
  return trial_ups?.find(({ status }) => !!status);
}
