import { PropsWithChildren, ReactElement } from "react";
import { Card, Flex, useMantineTheme } from "@mantine/core";
import { IWarningCardProps } from "./types";
import { useStyles } from "./WarningCard.styled";

export default function WarningCard({
  "data-testid": dataTestId,
  withBorder,
  maxWidth,
  spacing,
  children,
  bg,
  maw,
  w,
  ...cardProps
}: PropsWithChildren<IWarningCardProps>): ReactElement {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Card
      data-testid={dataTestId}
      className={classes.root}
      withBorder={withBorder}
      {...cardProps}
      bg={bg || "yellow.1"}
      maw={maxWidth === false ? "none" : maw || theme.breakpoints.sm}
      w={w || "100%"}
    >
      <Flex
        align="flex-start"
        direction="column"
        gap={spacing}
        justify="flex-start"
        wrap="nowrap"
      >
        {children}
      </Flex>
    </Card>
  );
}
