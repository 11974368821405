import HeaderNav from "@components/navigation/HeaderNav";
import { NextComponentType, NextPageContext } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { ReactElement, useState } from "react";
import { captureException } from "@sentry/nextjs";
import { QueryClientProvider } from "@common/utils/use-query";
import { MantineProvider, AppShell } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";

// GDPR
import "public/gdpr-cookie-notice/style.css";
import { isBrowser } from "@common/utils/browser";
import SideNav from "@components/navigation/SideNav";
import { defaultTheme as defaultThemeMantine } from "@common/constants/theme-mantine/constants";
import DefaultContainer from "@common/components/layout/DefaultContainer";
import { GoogleTagManager } from "@next/third-parties/google";
import { isProduction } from "@common/utils/config";
import AccountContainer from "@components/layout/AccountContainer";
import Tracking from "@components/thirdParty/Tracking";

type StoreAppComponentProps = NextComponentType<NextPageContext, any, any> & {
  hideNav?: boolean;
};

type StoreAppProps = AppProps & {
  Component: StoreAppComponentProps;
};

const baseAPIUrl = `${process.env.NEXT_PUBLIC_HARBORMASTER_API_BASE_URL}${process.env.NEXT_PUBLIC_HARBORMASTER_API_BASE_PATH}`;

export default function StoreApp({
  Component,
  pageProps,
}: StoreAppProps): ReactElement {
  const router = useRouter();
  const { pathname } = router;

  // are we in one of the checkout pages? `/checkout`
  const isCheckoutPathname = !!(pathname.indexOf("/checkout") > -1);

  // page
  const hideNav = Component.hideNav || false;

  // side nav
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Head>
        <title>The Metabase Store</title>
      </Head>

      {isBrowser() && isProduction() && (
        <GoogleTagManager
          gtmId={process.env.GTM}
          // preview={process.env.NODE_ENV}
        />
      )}

      <QueryClientProvider baseUrl={baseAPIUrl} onError={captureException}>
        <MantineProvider
          theme={defaultThemeMantine}
          withGlobalStyles
          withNormalizeCSS
        >
          <NotificationsProvider>
            <AccountContainer>
              <AppShell
                padding={0}
                navbarOffsetBreakpoint="sm"
                asideOffsetBreakpoint="sm"
                header={
                  !hideNav ? (
                    <HeaderNav
                      opened={opened}
                      onBurgerClick={() => setOpened(!opened)}
                    />
                  ) : undefined
                }
                navbar={
                  !hideNav && !isCheckoutPathname ? (
                    <SideNav opened={opened} />
                  ) : undefined
                }
              >
                <DefaultContainer {...pageProps}>
                  <Component {...pageProps} />
                </DefaultContainer>
              </AppShell>

              {isBrowser() && <Tracking />}
            </AccountContainer>
          </NotificationsProvider>
        </MantineProvider>
      </QueryClientProvider>
    </>
  );
}
