import {
  persistUTMQueryParamsToString,
  persistUTMQueryParamsToUrlObject,
} from "@common/utils/router";
import { MediaQuery, Navbar, NavLink } from "@mantine/core";
import Link from "next/link";
import { useRouter } from "next/router";
import { ReactElement, useCallback } from "react";
import { INavProps } from "../types";
import { SIDE_NAV_WIDTH } from "./constants";
import IconStar from "@common/components/icons/IconStar";
import IconCoin from "@common/components/icons/IconCoin";
import IconUser from "@common/components/icons/IconUser";
import IconGear from "@common/components/icons/IconGear";
import IconInfo from "@common/components/icons/IconInfo";
import IconBook from "@common/components/icons/IconBook";
import IconVisit from "@common/components/icons/IconVisit";
import IconPage from "@common/components/icons/IconPage";
import { purchasesStore } from "@stores/purchases";
import { useSessionStore } from "@stores/session";

export default function SideNav({ opened }: INavProps): ReactElement {
  const router = useRouter();
  const { pathname, query } = router;
  const { plan } = query;

  // purchases
  const { data: purchases } = purchasesStore.useGetPurchases();

  // session
  const { account, logout } = useSessionStore((state) => ({
    account: state.account,
    logout: state.logout,
  }));

  // logout
  const logoutCallback = useCallback(async () => {
    const { success } = await logout();
    if (success) {
      router.push(persistUTMQueryParamsToUrlObject(router, "/"));
    }
  }, [logout, router]);

  // are we in one of the checkout pages? `/checkout`
  const isNotSuccessStep = !!(plan !== "success");
  const isCheckoutPathname = !!(
    pathname.indexOf("/checkout") > -1 && isNotSuccessStep
  );
  const showAccountLink = !isCheckoutPathname || purchases?.length;

  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={SIDE_NAV_WIDTH}
    >
      <Navbar.Section grow>
        {account ? (
          <>
            {isNotSuccessStep && (
              <>
                <NavLink
                  component={Link}
                  label="Instances"
                  icon={<IconStar />}
                  mb="xs"
                  href={persistUTMQueryParamsToString(router, "/account")}
                  active={
                    pathname === "/account" ||
                    pathname === "/account/new-instance" ||
                    (pathname.startsWith("/account") &&
                      pathname.endsWith("/plan"))
                  }
                />
                <NavLink
                  component={Link}
                  label="Billing"
                  icon={<IconCoin />}
                  mb="xs"
                  href={persistUTMQueryParamsToString(
                    router,
                    "/account/manage/billing",
                  )}
                  active={pathname === "/account/manage/billing"}
                />
                <NavLink
                  component={Link}
                  label="Account Information"
                  icon={<IconUser size={16} />}
                  mb="xs"
                  href={persistUTMQueryParamsToString(
                    router,
                    "/account/manage",
                  )}
                  active={pathname === "/account/manage"}
                />
                <NavLink
                  component={Link}
                  label="Settings"
                  icon={<IconGear size={16} />}
                  mb="xs"
                  href={persistUTMQueryParamsToString(
                    router,
                    "/account/manage/settings",
                  )}
                  active={pathname === "/account/manage/settings"}
                />
              </>
            )}

            {(isCheckoutPathname || isNotSuccessStep) && (
              <>
                <NavLink
                  component="a"
                  label="Docs"
                  icon={<IconBook />}
                  mb="xs"
                  href={persistUTMQueryParamsToString(
                    router,
                    "https://metabase.com/docs",
                  )}
                  target="_blank"
                  rel="noreferrer"
                  rightSection={<IconVisit />}
                />
                <NavLink
                  component={Link}
                  label="Support"
                  icon={<IconInfo />}
                  mb="xs"
                  href={persistUTMQueryParamsToString(
                    router,
                    "https://www.metabase.com/help-premium",
                  )}
                  target="_blank"
                  rel="noreferrer"
                  rightSection={<IconVisit />}
                />
              </>
            )}

            {isNotSuccessStep && (
              <NavLink
                component={Link}
                label="Resources"
                icon={<IconPage size={18} />}
                mb="xs"
                href={persistUTMQueryParamsToString(
                  router,
                  "/account/resources",
                )}
                active={pathname === "/account/resources"}
              />
            )}
          </>
        ) : (
          <NavLink
            data-testid="nav-link-login"
            component={Link}
            label="Manage Metabase account"
            icon={undefined}
            href={persistUTMQueryParamsToString(router, "/")}
          />
        )}
      </Navbar.Section>

      {showAccountLink && (
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Navbar.Section>
            <NavLink
              data-testid="nav-link-logout"
              label="Logout"
              icon={undefined}
              onClick={logoutCallback}
            />
          </Navbar.Section>
        </MediaQuery>
      )}
    </Navbar>
  );
}
