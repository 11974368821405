import { useQuery, useMutation, useQueryClient } from "@common/utils/use-query";
import { BillingStore } from "./types";

export const billingStore: BillingStore = {
  useGetTaxIds() {
    return useQuery("/account/tax_ids");
  },

  useCreateTaxId() {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: "/account/tax_ids",
        method: "POST",
      },
      {
        onSuccess() {
          return queryClient.invalidateQueries("/account/tax_ids");
        },
      },
    );
  },

  useDeleteTaxId(id) {
    const queryClient = useQueryClient();
    return useMutation(
      { url: `/account/tax_ids/${id}`, method: "DELETE" },
      {
        onSuccess() {
          return queryClient.invalidateQueries("/account/tax_ids");
        },
      },
    );
  },

  useGetInvoices() {
    return useQuery("/account/invoices");
  },
};
