import {
  useQuery,
  useMutation,
  useQueryClient,
  StoreQueryType,
} from "@common/utils/use-query";
import { CustomerStoreType, ICustomer } from "./types";

export const customerStore: CustomerStoreType = {
  useGetDetails() {
    const query = useQuery<ICustomer>("/account/customer");

    // Empty billing address?
    if (query.data?.billing_address) {
      query.data = {
        ...query.data,
        billing_address: query.data.billing_address.country
          ? query.data.billing_address
          : null,
      };
    }

    // Empty shipping address?
    if (query.data?.shipping_address) {
      query.data = {
        ...query.data,
        shipping_address: query.data.shipping_address.country
          ? query.data.shipping_address
          : null,
      };
    }

    return query;
  },

  useUpdateBillingAddress() {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: "/account/customer/billing_address",
        method: "PUT",
      },
      {
        onSuccess() {
          queryClient.invalidateQueries("/account/customer");
        },
      },
    );
  },

  useUpdateShippingAddress() {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: "/account/customer/shipping_address",
        method: "PUT",
      },
      {
        onSuccess() {
          queryClient.invalidateQueries("/account/customer");
        },
      },
    );
  },

  useUpdatePONumber() {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: "/account/customer/purchase_order_number",
        method: "PUT",
      },
      {
        onSuccess() {
          queryClient.invalidateQueries("/account/customer");
        },
      },
    );
  },

  useDeletePONumber(): StoreQueryType<ICustomer> {
    const queryClient = useQueryClient();
    const query = useQuery<ICustomer>(
      {
        url: "/account/customer/purchase_order_number",
        method: "DELETE",
      },
      {
        enabled: false,
        onSuccess() {
          queryClient.invalidateQueries("/account/customer");
        },
      },
    );
    return query;
  },
};
