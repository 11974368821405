import { UnstyledButton } from "@mantine/core";
import { PropsWithChildren, ReactElement } from "react";
import { useStyles } from "./ButtonAsText.styled";
import { IButtonAsTextProps } from "./types";

export default function ButtonAsText({
  component,
  onClick,
  disabled,
  children,
  ...unstyledButtonProps
}: PropsWithChildren<IButtonAsTextProps>): ReactElement {
  const { classes } = useStyles();

  return (
    <UnstyledButton
      component={component}
      className={classes.root}
      onClick={onClick}
      disabled={disabled}
      {...unstyledButtonProps}
    >
      {children}
    </UnstyledButton>
  );
}
